import clsx from 'clsx';
import useI18n from '@i18n';

import SectionTitle from '@components/pages/home/epnbz/Base/SectionTitle';
import { companyName } from '@config/globalDomain';
import { useBreakpoints, ScreenTypes } from '@hooks/useBreakpoints';
import NetworkCard from './NetworkCard';

import styles from './Network.module.scss';

import advertiserImg from './img/advertiser.png';
import epnImg from './img/epn.png';
import webmasterImg from './img/wm.png';

const IMAGES = {
    mobile: {
        advertiser: advertiserImg,
        epn: epnImg,
        webmaster: webmasterImg,
    },
    desktop: {
        advertiser: advertiserImg,
        epn: epnImg,
        webmaster: webmasterImg,
    },
};

const mobileImagesCondition = [ScreenTypes.xs, ScreenTypes.sm];

const BlockNetwork: React.FC = () => {
    const { t } = useI18n();

    const breakpoint = useBreakpoints();

    const images = mobileImagesCondition.includes(breakpoint) ? IMAGES.mobile : IMAGES.desktop;

    return (
        <section className={clsx(styles.landingContainer, styles.wrapper)}>
            <SectionTitle classname={styles.title}>{t('How it works?')}</SectionTitle>
            <div className={styles.network}>
                <NetworkCard
                    imgSrc={images.advertiser}
                    title={t('Cryptocurrency advertiser')}
                    text={t(
                        'Seeks to increase the number of users for their product by placing their offer on ePN Affiliate',
                        { companyName },
                    )}
                />
                <div className={styles.network_arrow} />
                <NetworkCard
                    imgSrc={images.webmaster}
                    title={t('Traffic owner')}
                    text={t(
                        "Attracts traffic to advertiser's project. If referred users perform target actions, they receive a reward",
                    )}
                />
                <div className={clsx(styles.network_arrow, styles.network_arrow__invert)} />
                <NetworkCard
                    imgSrc={images.epn}
                    title="ePN Affiliate"
                    text={t(
                        'Provides its platform, bringing advertisers and webmasters together. It monitors the quality of traffic and pays out rewards',
                    )}
                />
            </div>
        </section>
    );
};

export default BlockNetwork;
